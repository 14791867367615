import React from 'react'
import Layout from '../../components/Layout'
import { Link, graphql } from 'gatsby'
import Head from '../../components/Head';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';


export default function Services({ data }) {
    const industrialAutomationProjects = data.industrialAutomationProjects.nodes
    const machineApplicationDevelopment = data.machineApplicationDevelopment.nodes

    return (
        <Layout>
            <Head title="Services" />
            <div className="container">
                <div className="row text-muted shadow-lg">
                    <div className="col-lg-3 dark-contrast-border lh-lg p-2">
                        <div className="sticky-top">
                            <h1 className="brand-color fw-bold text-end" style={{ fontSize: "3.5rem" }}>
                                services.
                            </h1>
                            <p className="fw-light text-end">
                                that deliver.
                            </p>
                            <ul className="text-end page-nav mb-5">
                                <div>
                                    <ul>
                                        <li className="mb-2" key="industrial-automation-projects">
                                            <Link to="#industrial-automation-projects">Industrial Automation Projects</Link>
                                        </li>
                                        <li className="mb-2" key="machine-application-development-and-troubleshooting">
                                            <Link to="#machine-application-development-and-troubleshooting">Machine Application Development & Troubleshooting</Link>
                                        </li>
                                    </ul>
                                </div>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-9 dark-contrast p-3 text-muted">
                        <small>We provide best in class services.</small>
                        <div className="mt-5"></div>
                        <div>
                            <section id="industrial-automation-projects">
                                <h4 className="brand-color border-bottom border-info lh-lg fw-light">Industrial Automation Projects</h4>
                                {industrialAutomationProjects.map(data => (
                                    <div className="fw-light bg-transparent m-2">
                                        <button className="btn collapsible-btn text-start w-100 border-dark shadow-lg p-3 rounded-0 fw-light" type="button" data-bs-toggle="collapse" data-bs-target={`#${data.frontmatter.slug}-body`} aria-expanded="false" aria-controls="collapseExample">
                                            {data.frontmatter.title}
                                        </button>
                                        <div className="collapse" id={`${data.frontmatter.slug}-body`}>
                                            <div className="card card-body bg-dark lh-lg rounded-0 ">
                                                <div className="row align-items-center">
                                                    <div className="col-lg-6">
                                                        <div>
                                                            <div dangerouslySetInnerHTML={{ __html: data.html }} className="align-self-center" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <GatsbyImage image={getImage(data.frontmatter.image.childImageSharp.gatsbyImageData)} alt={`${data.frontmatter.slug}`} />
                                                    </div>
                                                </div>
                                                <div className="card-footer dark-contrast text-center border border-dark">
                                                    <small><Link to="/contact-us" className="brand-color text-decoration-none">Contact Us</Link> to know more.</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </section>

                            <section id="machine-application-development-and-troubleshooting">
                                <h4 className="brand-color border-bottom border-info lh-lg fw-light">Machine Application Development & Troubleshooting</h4>
                                {machineApplicationDevelopment.map(data => (
                                    <div className="fw-light bg-transparent m-2">
                                        <button className="btn collapsible-btn text-start w-100 border-dark shadow-lg p-3 rounded-0 fw-light" type="button" data-bs-toggle="collapse" data-bs-target={`#${data.frontmatter.slug}-body`} aria-expanded="false" aria-controls="collapseExample">
                                            {data.frontmatter.title}
                                        </button>
                                        <div className="collapse" id={`${data.frontmatter.slug}-body`}>
                                            <div className="card card-body bg-dark lh-lg rounded-0 ">
                                                <div className="row align-items-center">
                                                    <div className="col-lg-6">
                                                        <div>
                                                            <div dangerouslySetInnerHTML={{ __html: data.html }} className="align-self-center" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <GatsbyImage image={getImage(data.frontmatter.image.childImageSharp.gatsbyImageData)} alt={`${data.frontmatter.slug}`} />
                                                    </div>
                                                </div>
                                                <div className="card-footer dark-contrast text-center border border-dark">
                                                    <small><Link to="/contact-us" className="brand-color text-decoration-none">Contact Us</Link> to know more.</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}


export const query = graphql`
query ServicesQuery {
    industrialAutomationProjects: allMarkdownRemark(
        filter: {
            frontmatter: {
                category: 
                {eq: "Industrial Automation Projects"}
            }
        }
    ) 
    {
        nodes {
            id
            frontmatter {
                title
                slug
                image {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
            html
        }
    }
    machineApplicationDevelopment: allMarkdownRemark(
        filter: {
            frontmatter: {
                category: 
                {eq: "Machine Application Development & Troubleshooting"}
            }
        }
    ) 
    {
        nodes {
            id
            frontmatter {
                title
                slug
                image {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
            html
        }
    }
}
`